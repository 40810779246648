/* eslint-disable react-hooks/rules-of-hooks */
import { NavLink as RouterLink, useLocation } from 'react-router-dom';

// material
import { experimentalStyled as styled, useTheme, withStyles } from '@material-ui/core/styles';
import {
  TextField,
  Box,
  AppBar,
  Toolbar,
  InputAdornment,
  Button,
  Container,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import SearchIcon from '@material-ui/icons/Search';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
import useAuth from '../../hooks/useAuth';
// components
import Logo from '../../components/Logo';
import { MHidden } from '../../components/@material-extend';

//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';
import AccountPopover from '../dashboard/AccountPopover';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 74;
const APP_BAR_DESKTOP = 124;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8
}));

const SearchBarStyle = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('xs')]: { display: 'flex', alignItems: 'center', marginBottom: 0 },
  [theme.breakpoints.up('md')]: { marginBottom: 10 }
}));

const SearchBoxStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: { display: 'block' }
}));

const TextFieldStyle = withStyles(() => ({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
      borderLeft: '0px solid',
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(145, 158, 171, 0.32)'
      },
      '&:focus-visible': {
        outline: '-webkit-focus-ring-color auto 0px'
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1px solid rgba(0, 0, 0, 0.23)'
      }
    }
  }
}))(TextField);

// ----------------------------------------------------------------------

export default function MainNavbar() {
  const { user, logout } = useAuth();
  const theme = useTheme();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  let isOffset = false;
  if (isMobile) {
    isOffset = useOffSetTop(0);
  } else {
    isOffset = useOffSetTop(700);
  }
  const { pathname } = useLocation();
  const isHome = pathname === '/';

  return (
    <AppBar color="transparent" sx={{ boxShadow: 0 }}>
      <ToolbarStyle
        disableGutters
        sx={{
          bgcolor: 'background.default',
          background: 'white',
          height: { md: APP_BAR_DESKTOP - 16 },
          color: 'primary'
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <RouterLink to="/" style={{ textDecoration: 'none' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Logo />
              <Typography
                variant="h5"
                sx={{
                  color: 'common.black',
                  marginLeft: '10px',
                  marginTop: '10px',
                  fontFamily: 'sans-serif',
                  display: 'none',
                  [theme.breakpoints.up('md')]: {
                    display: 'block'
                  }
                }}
              >
                BitcoinCustomers
              </Typography>
            </Box>
          </RouterLink>
          <SearchBoxStyle>
            <SearchBarStyle>
              <TextFieldStyle
                id="input-with-icon-textfield"
                size="small"
                placeholder="Products, retailers, and more"
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderTopLeftRadius: 10,
                    borderBottomLeftRadius: 10
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />
              <TextFieldStyle
                id="input-with-icon-textfield"
                size="small"
                placeholder="Store locations"
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderTopRightRadius: 10,
                    borderBottomRightRadius: 10,
                    borderLeft: 'none'
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <RoomIcon />
                    </InputAdornment>
                  )
                }}
              />
            </SearchBarStyle>
            <MHidden width="mdDown">
              <MenuDesktop navConfig={navConfig} />
            </MHidden>
          </SearchBoxStyle>
          <MHidden width="mdUp">
            <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} logout={logout} />
          </MHidden>
          <MHidden width="mdDown">
            {user ? (
              <AccountPopover />
            ) : (
              <Box sx={{ display: 'flex' }}>
                <Button variant="outlined" sx={{ mr: 1 }} href={PATH_AUTH.login}>
                  Login
                </Button>
                <Button variant="contained" href={PATH_AUTH.register}>
                  Get Started
                </Button>
              </Box>
            )}
          </MHidden>
        </Container>
      </ToolbarStyle>
      <ToolbarShadowStyle />
    </AppBar>
  );
}
