/* eslint-disable array-callback-return */
import PropTypes from 'prop-types';
import { Container, Alert, AlertTitle } from '@material-ui/core';
// hooks
import useAuth from '../hooks/useAuth';
// ----------------------------------------------------------------------

RoleAdminGuard.propTypes = {
  children: PropTypes.node
};

export default function RoleAdminGuard({ children }) {
  const { user } = useAuth();

  if (user.roles !== 'Super') {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>Permission Denied</AlertTitle>
          You do not have permission to access this page
        </Alert>
      </Container>
    );
  }

  return <>{children}</>;
}
