/* eslint-disable react-hooks/rules-of-hooks */
import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';

import OwnerBasedGuard from '../guards/OwnerBasedGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
import RoleAuthGuard from '../guards/RoleAuthGuard';
import RoleAdminGuard from '../guards/RoleAdminGuard';
// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '/',
          element: <Navigate to="/dashboard/my-account" replace />
        },

        // admin
        {
          path: '/admin/view-company',
          element: (
            <RoleAdminGuard>
              <ViewCompany />
            </RoleAdminGuard>
          )
        },
        {
          path: '/admin/view-company/:companyName/:companyId',
          element: (
            <RoleAdminGuard>
              <ViewCompanyDetail />
            </RoleAdminGuard>
          )
        },
        {
          path: '/admin/view-users',
          element: (
            <RoleAdminGuard>
              <ViewUsers />
            </RoleAdminGuard>
          )
        },

        // user
        { path: '/analytics', element: <Dashboard /> },
        {
          path: 'my-account',
          element: <MyAccount />
        },
        {
          path: 'create-company',
          element: <CompanyCreate />
        },
        {
          path: 'company/:companyId/edit',
          element: (
            <OwnerBasedGuard>
              <CompanyCreate />
            </OwnerBasedGuard>
          )
        },

        // stores
        {
          path: '/stores',
          element: (
            <RoleBasedGuard>
              <StoreList />
            </RoleBasedGuard>
          )
        },
        {
          path: '/stores/add-store',
          element: <CompanyStoreCreate />
        },
        {
          path: '/stores/:storeName/:storeId/edit',
          element: <CompanyStoreCreate />
        },
        {
          path: '/stores/:storeName/:storeId/:location',
          element: <CompanyStoreDetail />
        },

        // products
        {
          path: 'products',
          element: (
            <RoleBasedGuard>
              <AllProductList />
            </RoleBasedGuard>
          )
        },
        {
          path: '/products/create-product',
          element: <CompanyProductCreate />
        },
        {
          path: '/products/:productName/:productId/edit',
          element: <CompanyProductCreate />
        },

        // employee
        {
          path: 'employees',
          element: (
            <RoleAuthGuard>
              <Employees />
            </RoleAuthGuard>
          )
        },
        {
          path: 'employees/add-employee',
          element: (
            <RoleAuthGuard>
              <EmployeeCreate />
            </RoleAuthGuard>
          )
        }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '/', element: <LandingPage /> },
        { path: '/stores', element: <EcommerceStores /> },
        { path: '/store/:storeName/:storeId', element: <EcommerceStoreDetails /> },
        { path: '/store/:storeName/:storeId/product/:productName/:productId', element: <EcommerceProductDetails /> },
        { path: '/contact-us', element: <Contact /> },
        { path: '/about-us', element: <About /> },
        { path: '/faqs', element: <Faqs /> },
        { path: '/terms-condition', element: <TermsAndCondition /> },
        { path: '/privacy-policy', element: <PrivacyPolicy /> }
      ]
    },

    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS
// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
// Dashboard
const Dashboard = Loadable(lazy(() => import('../pages/Dashboard')));

// admin
const ViewCompany = Loadable(lazy(() => import('../pages/dashboard/AdminViewCompany')));
const ViewCompanyDetail = Loadable(lazy(() => import('../pages/dashboard/AdminCompanyDetails')));
const ViewUsers = Loadable(lazy(() => import('../pages/dashboard/AdminViewUsers')));

// user
const MyAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const CompanyCreate = Loadable(lazy(() => import('../pages/dashboard/CompanyCreate')));

const StoreList = Loadable(lazy(() => import('../pages/dashboard/CompanyStoreList')));
const CompanyStoreCreate = Loadable(lazy(() => import('../pages/dashboard/CompanyStoreCreate')));
const CompanyStoreDetail = Loadable(lazy(() => import('../pages/dashboard/CompanyStoreDetail')));

const AllProductList = Loadable(lazy(() => import('../pages/dashboard/CompanyProductList')));
const CompanyProductCreate = Loadable(lazy(() => import('../pages/dashboard/CompanyProductCreate')));

const Employees = Loadable(lazy(() => import('../pages/dashboard/Employees')));
const EmployeeCreate = Loadable(lazy(() => import('../pages/dashboard/EmployeeCreate')));

const NotFound = Loadable(lazy(() => import('../pages/Page404')));
// const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));

// Main
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
const EcommerceStores = Loadable(lazy(() => import('../pages/EcommerceStores')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/EcommerceProductDetails')));
const EcommerceStoreDetails = Loadable(lazy(() => import('../pages/EcommerceStoreDetails')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const About = Loadable(lazy(() => import('../pages/About')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const TermsAndCondition = Loadable(lazy(() => import('../pages/TermsAndCondition')));
const PrivacyPolicy = Loadable(lazy(() => import('../pages/PrivacyPolicy')));

// children: [
//   {
//     path: '/',
//     element: <Navigate to="/dashboard/papers/overview" replace />
//   },
//   { path: 'overview', element: <Maintenance /> },
//   { path: 'published', element: <Maintenance /> },
//   { path: 'create', element: <Maintenance /> },
//   {
//     path: '/:paperId/edit',
//     element: (
//       <RoleBasedGuard>
//         <Maintenance />
//       </RoleBasedGuard>
//     )
//   },
//   { path: '/:paperId/detail', element: <Maintenance /> }
// ]
