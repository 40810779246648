/* eslint-disable array-callback-return */
import PropTypes from 'prop-types';
import { Container, Alert, AlertTitle } from '@material-ui/core';
import { useParams } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// ----------------------------------------------------------------------

OwnerBasedGuard.propTypes = {
  children: PropTypes.node
};

export default function OwnerBasedGuard({ children }) {
  const { user } = useAuth();
  const currentCompanyId = Number(useParams().companyId);
  const { companyId } = user;

  if (currentCompanyId !== companyId) {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>Permission Denied</AlertTitle>
          You do not have permission to access this page.
        </Alert>
      </Container>
    );
  }

  return <>{children}</>;
}
