import { createSlice } from '@reduxjs/toolkit';
// import { filter } from 'lodash';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  company: null,
  companyList: [],
  userList: []
};

const slice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET COMPANY
    getCompanySuccess(state, action) {
      state.isLoading = false;
      state.company = action.payload;
    },

    // GET COMPANY LIST
    getCompanyListSuccess(state, action) {
      state.isLoading = false;
      state.companyList = action.payload;
    },

    // GET COMPANY LIST
    getAllUserListSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getCompany(companyId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/company/getCompany', {
        params: { companyId }
      });
      dispatch(slice.actions.getCompanySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCompanyList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/company/getCompanyList');
      dispatch(slice.actions.getCompanyListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllUserList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/company/getAllUserList');
      dispatch(slice.actions.getAllUserListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
// Create
// ----------------------------------------------------------------------

export function createCompany({ companyList }) {
  const data = companyList;
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/company/createCompany', data);
      return 'success';
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------
// Update
// ----------------------------------------------------------------------

export function updateCompany({ companyList }) {
  const data = companyList;
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/company/updateCompany', data);
      return 'success';
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function banOrApprovalCompany({ data }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/company/banOrApprovalCompany', data);
      return 'success';
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}
