import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import userReducer from './slices/user';
import productReducer from './slices/product';
import storeReducer from './slices/store';
import companyReducer from './slices/company';
import employeeReducer from './slices/employee';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const rootReducer = combineReducers({
  user: userReducer,
  store: storeReducer,
  product: productReducer,
  employee: employeeReducer,
  company: companyReducer
});

export { rootPersistConfig, rootReducer };
