import { reject } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  stores: [],
  store: null
};

const slice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET STORES
    getStoresSuccess(state, action) {
      state.isLoading = false;
      state.stores = action.payload;
    },

    // GET STORE
    getStoreSuccess(state, action) {
      state.isLoading = false;
      state.store = action.payload;
    },

    // DELETE STORE
    deleteStore(state, action) {
      state.stores = reject(state.stores, { id: action.payload });
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteStore } = slice.actions;

// ----------------------------------------------------------------------

export function getStoreList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/store/getStoreList');
      dispatch(slice.actions.getStoresSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllStores() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/store/getAllStoreList');
      dispatch(slice.actions.getStoresSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getStoreDetail(storeId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/store/getStoreDetail', {
        params: { storeId }
      });
      dispatch(slice.actions.getStoreSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------
// Create
// ----------------------------------------------------------------------

export function createStore(storeData, serviceTime) {
  const data = { storeData, serviceTime };
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/store/createStore', data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createStoreReview(reviewData) {
  const data = { reviewData };
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/store/createStoreReview', data);
      return 'success';
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function createReplyStoreReview(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.post('/api/store/createReplyStoreReview', data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function saveAcceptedPayments(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.post('/api/store/saveAcceptedPayments', data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------
// Update
// ----------------------------------------------------------------------

export function updateStore(storeData, serviceTime) {
  const data = { storeData, serviceTime };
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/store/updateStore', data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
// Delete
// ----------------------------------------------------------------------

export function deleteStoreFromCompany(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/store/deleteStoreFromCompany', data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
