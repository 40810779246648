import { Icon } from '@iconify/react';
import googleFill from '@iconify/icons-eva/google-fill';
import twitterFill from '@iconify/icons-eva/twitter-fill';
import facebookFill from '@iconify/icons-eva/facebook-fill';
import linkedinFill from '@iconify/icons-eva/linkedin-fill';
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
// material
import { experimentalStyled as styled, useTheme } from '@material-ui/core/styles';
import { Grid, Link, Divider, Container, Typography, IconButton, Stack, Box } from '@material-ui/core';
//
import Logo from '../../components/Logo';

// routes
import { PATH_EXTENAL_PAGE } from '../../routes/paths';

// ----------------------------------------------------------------------

const SOCIALS = [
  { name: 'FaceBook', icon: facebookFill },
  { name: 'Google', icon: googleFill },
  { name: 'Linkedin', icon: linkedinFill },
  { name: 'Twitter', icon: twitterFill }
];

const LINKS = [
  {
    headline: 'BitcoinCustomers',
    children: [
      { name: 'About us', href: PATH_EXTENAL_PAGE.about },
      { name: 'Contact us', href: PATH_EXTENAL_PAGE.contact },
      { name: 'FAQs', href: PATH_EXTENAL_PAGE.faqs }
    ]
  },
  {
    headline: 'Legal',
    children: [
      { name: 'Terms and Condition', href: PATH_EXTENAL_PAGE.termsCondition },
      { name: 'Privacy Policy', href: PATH_EXTENAL_PAGE.privacyPolicy }
    ]
  },
  {
    headline: 'Contact',
    children: [
      { name: 'info@bitcoincustomers.com', href: 'mailto:info@bitcoincustomers.com' },
      { name: 'Boston, Massachusetts, United Status', href: '#' }
    ]
  }
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  const theme = useTheme();
  return (
    <RootStyle>
      <Divider />
      <Container maxWidth="lg" sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <ScrollLink to="move_top" spy smooth>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  [theme.breakpoints.up('md')]: { justifyContent: 'left' }
                }}
              >
                <Logo />
                <Typography
                  variant="h5"
                  sx={{
                    ml: { xs: 0, md: 2 },
                    marginTop: '10px',
                    fontFamily: 'sans-serif'
                  }}
                >
                  BitcoinCustomers
                </Typography>
              </Box>
            </ScrollLink>
          </Grid>
          <Grid item xs={8} md={3}>
            <Stack
              spacing={1.5}
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{ mt: 0, mb: { xs: 5, md: 0 }, ml: { xs: 0, md: 8 } }}
            >
              {SOCIALS.map((social) => (
                <IconButton key={social.name} color="primary" sx={{ p: 1 }}>
                  <Icon icon={social.icon} width={16} height={16} />
                </IconButton>
              ))}
            </Stack>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
              {LINKS.map((list) => {
                const { headline, children } = list;
                if (headline !== 'Contact') {
                  return (
                    <Stack key={headline} spacing={2}>
                      <Typography component="p" variant="overline">
                        {headline}
                      </Typography>
                      {children.map((link) => (
                        <Link
                          to={link.href}
                          key={link.name}
                          color="inherit"
                          variant="body2"
                          component={RouterLink}
                          sx={{ display: 'block' }}
                        >
                          {link.name}
                        </Link>
                      ))}
                    </Stack>
                  );
                }
                return (
                  <Stack key={headline} spacing={2}>
                    <Typography component="p" variant="overline">
                      {headline}
                    </Typography>
                    {children.map((link) => (
                      <Typography key={link.name} color="inherit" variant="body2" sx={{ display: 'block' }}>
                        {link.name}
                      </Typography>
                    ))}
                  </Stack>
                );
              })}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'left' }
          }}
        >
          © 2022. All rights reserved
        </Typography>
      </Container>
    </RootStyle>
  );
}
