// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_AUTH = '/auth';
// ----------------------------------------------------------------------

export const PATH_EXTENAL_PAGE = {
  root: '/',
  contact: '/contact-us',
  about: '/about-us',
  faqs: '/faqs',
  termsCondition: '/terms-condition',
  privacyPolicy: '/privacy-policy'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  analytics: path(ROOTS_DASHBOARD, '/analytics'),
  myAccount: path(ROOTS_DASHBOARD, '/my-account'),

  // company
  createCompany: path(ROOTS_DASHBOARD, '/create-company'),

  // store
  stores: path(ROOTS_DASHBOARD, '/stores'),
  addStore: path(ROOTS_DASHBOARD, '/stores/add-store'),
  addProduct: path(ROOTS_DASHBOARD, '/stores/:store/add-product'),

  // product
  products: path(ROOTS_DASHBOARD, '/products'),
  createProduct: path(ROOTS_DASHBOARD, '/products/create-product'),

  // employee
  employees: path(ROOTS_DASHBOARD, '/employees'),
  addEmployee: path(ROOTS_DASHBOARD, '/employees/add-employee'),

  // admin
  viewCompany: path(ROOTS_DASHBOARD, '/admin/view-company'),
  viewUsers: path(ROOTS_DASHBOARD, '/admin/view-users')
};

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};
