/* eslint-disable no-else-return */
import PropTypes from 'prop-types';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
// material
import { withStyles } from '@material-ui/styles';
import { Box, Link, Stack, Button } from '@material-ui/core';

// ----------------------------------------------------------------------

const LinkButtonStyle = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0.6, 3),
    borderRadius: theme.spacing(3),
    '& a': {
      fontWeight: '100',
      fontSize: '16px'
    }
  }
}))(Button);

// ----------------------------------------------------------------------

IconBullet.propTypes = {
  type: PropTypes.oneOf(['subheader', 'item'])
};

function IconBullet({ type = 'item' }) {
  return (
    <Box sx={{ width: 24, height: 16, display: 'flex', alignItems: 'center' }}>
      <Box
        component="span"
        sx={{
          ml: '2px',
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'currentColor',
          ...(type !== 'item' && { ml: 0, width: 8, height: 2, borderRadius: 2 })
        }}
      />
    </Box>
  );
}

MenuDesktopItem.propTypes = {
  item: PropTypes.object
};

function MenuDesktopItem({ item }) {
  const { title, path } = item;
  // const isActive = pathname === path;

  if (title === 'Get Started') {
    return <></>;
  }
  return (
    <LinkButtonStyle color="secondary">
      <Link
        key={title}
        to={path}
        component={RouterLink}
        sx={{
          '&:hover': {
            textDecoration: 'none'
          },
          color: 'text.primary'
        }}
      >
        {title}
      </Link>
    </LinkButtonStyle>
  );
}

MenuDesktop.propTypes = {
  navConfig: PropTypes.array
};

export default function MenuDesktop({ navConfig }) {
  const { pathname } = useLocation();

  return (
    <Stack direction="row" justifyContent="center">
      {navConfig.map((link) => (
        <MenuDesktopItem key={link.title} item={link} pathname={pathname} />
      ))}
    </Stack>
  );
}
