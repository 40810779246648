/* eslint-disable array-callback-return */
import PropTypes from 'prop-types';
import { Container, Alert, AlertTitle } from '@material-ui/core';
// hooks
import useAuth from '../hooks/useAuth';
// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  children: PropTypes.node
};

export default function RoleBasedGuard({ children }) {
  const { user } = useAuth();
  const { companyStatus } = user;

  if (companyStatus !== 'approved') {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>Permission Denied</AlertTitle>
          You do not have permission to access this page, your company is not approved yet.
        </Alert>
      </Container>
    );
  }

  return <>{children}</>;
}
