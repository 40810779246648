import RoomIcon from '@material-ui/icons/Room';
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import GavelIcon from '@material-ui/icons/Gavel';
import InfoIcon from '@material-ui/icons/Info';
// routes
import { PATH_AUTH, PATH_EXTENAL_PAGE } from '../../routes/paths';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22
};

const menuConfig = [
  {
    title: 'Map',
    path: '/',
    icon: <RoomIcon {...ICON_SIZE} />
  },
  {
    title: 'Stores',
    path: '/stores',
    icon: <LocalGroceryStoreIcon {...ICON_SIZE} />
  },
  {
    title: 'Contact',
    path: PATH_EXTENAL_PAGE.contact,
    icon: <PermContactCalendarIcon {...ICON_SIZE} />
  },
  {
    title: 'About',
    path: PATH_EXTENAL_PAGE.about,
    icon: <InfoIcon {...ICON_SIZE} />
  },
  {
    title: 'Get Started',
    path: PATH_AUTH.login,
    icon: <GavelIcon {...ICON_SIZE} />
  }
];

export default menuConfig;
