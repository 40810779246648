// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  dashboard: getIcon('ic_dashboard'),
  account: getIcon('ic_user'),
  company: getIcon('ic_entities'),
  products: getIcon('ic_cart'),
  employees: getIcon('ic_teams')
};

const sidebarConfig = [
  // SUPER ADMIN
  // ----------------------------------------------------------------------
  {
    subheader: 'Admin',
    items: [
      {
        title: 'My Account',
        path: PATH_DASHBOARD.myAccount,
        icon: ICONS.account
      },
      {
        title: 'View Companies',
        path: PATH_DASHBOARD.viewCompany,
        icon: ICONS.company
      },
      {
        title: 'View Users',
        path: PATH_DASHBOARD.viewUsers,
        icon: ICONS.employees
      }
    ]
  },
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'Management',
    items: [
      {
        title: 'My Account',
        path: PATH_DASHBOARD.myAccount,
        icon: ICONS.account
      },
      {
        title: 'Company',
        path: PATH_DASHBOARD.stores,
        icon: ICONS.company
      },
      {
        title: 'All Products',
        path: PATH_DASHBOARD.products,
        icon: ICONS.products
      },
      {
        title: 'Employees',
        path: PATH_DASHBOARD.employees,
        icon: ICONS.employees
      }
    ]
  }
];

export default sidebarConfig;
