// material
import { Box } from '@material-ui/core';

import { useTheme } from '@material-ui/core/styles';
// ----------------------------------------------------------------------

export default function Logo({ ...other }) {
  const theme = useTheme();
  return (
    <Box
      component="img"
      alt="logo"
      src="/static/brand/logo.png"
      sx={{
        height: 30,
        minWidth: 35,
        mr: { xs: 1, md: 0 },
        [theme.breakpoints.up('sm')]: { height: 50, width: 'auto' }
      }}
      {...other}
    />
  );
}
