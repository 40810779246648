/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { Icon } from '@iconify/react';
import { useRef, useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import fileProtectOutlined from '@iconify/icons-ant-design/file-protect-outlined';
import homeOutlined from '@iconify/icons-ant-design/home-outlined';
import bankTwotone from '@iconify/icons-ant-design/bank-twotone';
// material
import { alpha } from '@material-ui/core/styles';
import { Avatar, Button, Box, Divider, MenuItem, Typography } from '@material-ui/core';
// hooks
import useAuth from '../../hooks/useAuth';

// routes
import { PATH_DASHBOARD, PATH_AUTH } from '../../routes/paths';
// components
import { MIconButton } from '../../components/@material-extend';
import MenuPopover from '../../components/MenuPopover';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  { label: 'Home', icon: homeOutlined, linkTo: '/' },
  { label: 'My Company', icon: bankTwotone, linkTo: PATH_DASHBOARD.root },
  { label: 'Company Profile', icon: fileProtectOutlined, linkTo: PATH_DASHBOARD.root }
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [menuOptions, setMenuOptions] = useState(MENU_OPTIONS);

  const { companyId } = user;

  const linkTo = `${PATH_DASHBOARD.root}/company/${companyId}/edit`;

  useEffect(() => {
    const { roles } = user;
    if (roles === 'Owner') {
      const tmpMenu = [];
      MENU_OPTIONS.map((item) => {
        if (companyId !== 0) {
          if (item.label === 'Company Profile') tmpMenu.push({ ...item, linkTo });
          else tmpMenu.push(item);
        } else if (item.label !== 'Company Profile') tmpMenu.push(item);
      });
      setMenuOptions(tmpMenu);
    } else {
      const tmpMenu = [];
      MENU_OPTIONS.map((item) => {
        if (item.label === 'My Company') tmpMenu.push({ ...item, label: 'My Account' });
        else if (item.label !== 'Company Profile') tmpMenu.push(item);
      });
      setMenuOptions(tmpMenu);
    }
  }, [user]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar alt={user.firstName} src={user.photoURL} />
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 220 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {user.firstName} {user.lastName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {menuOptions.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={() => {
              logout();
              navigate(PATH_AUTH.login);
            }}
          >
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
